
.infinityScrollHolder {
    flex: 1;
    display: flex;
    overflow: auto;
    margin-left: 1em;
    max-height: 375px;
    margin-right: 1em;
    padding-top: 1em;
    flex-direction: column;
}

.wrapper {
    width: 448px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    padding: 24px 48px 40px;
    background: var(--fourth-container-background-color);
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
}

.formContent {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.title {
    font-size: 28px;
    font-weight: 800;
    line-height: 42px;
    color: var(--primary-font-color);
}
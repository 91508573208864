.headerIcon {
    cursor: pointer;
    color: var(--primary-font-color);
}

.content {
    width: 450px;
    display: flex;
    overflow-y: auto;
    text-align: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--fourth-container-background-color);
}

.content::-webkit-scrollbar {
    width: 5px;
}

.content::-webkit-scrollbar-track {
    background: transparent;
}

.content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--ternary-border-color);
}

.content::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-border-color);

}

.simpleContent {
    width: 433px;
    padding: 24px;
    border-radius: 8px;
    background: var(--fourth-container-background-color);
}

.header {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.headerTitle {
    font-size: 28px;
    font-weight: 700;
    padding-left: 16px;
    color: var(--primary-font-color);
}

.footer {
    padding: 24px 0 0 0;
    border-top: 1px solid var(--primary-border-color);
}


/**
    Popups with icons
 */

.modalWithIcon {
    display: flex;
    padding: 25px 30px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background: var(--fourth-container-background-color);
}

.removeTop {
    padding-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}


.iconHolder {
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: var(--danger-primary-color);
}

.icon {
    color: var(--secondar-font-color);;
}

.imgStyle {
    object-fit: contain;
}

.iconWrapper {
    top: 0;
    padding: 10px;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    background: var(--fourth-container-background-color);
}

.title {
    font-size: 1.3em;
    font-weight: 800;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 15px;
    text-transform: capitalize;
    color: var(--primary-font-color);
}

.titleBottom {
    width: 100%;
    padding-bottom: 1em;
    border-bottom: 1px solid var(--primary-border-color);
}

.description {
    font-size: .9em;
    margin-top: 5px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 15px;
    color: var(--primary-font-color);
}


.footer {
    width: 100%;
    margin-top: 30px;
}

.footerRow {
    display: flex;
    flex-direction: row;
}


.altHeader {
    display: flex;
    padding: 15px;
    font-size: 1.2em;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: var(--secondar-font-color);
    font-family: var(--primary-font-family);
    background-color: var(--primary-font-color);
}
.sidebar {
    z-index: 10;
    height: 100%;
    position: fixed;
    transition: all 0.5s;
    width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    min-width: var(--sidebar-width);
    background: var(--ternary-container-background-color);
}

.isOpen {
    margin-left: 0;
    min-width: 275px;
    max-width: 275px;
    transition: 0.5s;
}

.sideMenu {
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.sideMenu::-webkit-scrollbar {
    width: 0;
}

.sideMenu::-webkit-scrollbar-thumb {
    border-radius: 3px;
}

.sideMenu::-webkit-scrollbar-thumb:hover {
}

.header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /*height: calc(var(--navbar-height) - 10px);*/
}

.separator {
    width: 50px;
    height: 1px;
    opacity: .3;
    background: var(--secondary-border-color);
}

.content {
    padding-top: 30px;
}

.logo {
    cursor: pointer;
}

.footer {
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.modalFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.left {
    display: flex;
    align-items: center;
}

.currentUserInfo {
    display: flex;
    padding-left: 16px;
    flex-direction: column;
}

.currentUserName {
    font-weight: 600;
    text-transform: capitalize;
    color: var(--fourth-border-color);
}

.currentLabel {
    font-size: 10px;
    line-height: 1.8em;
    text-transform: uppercase;
    color: var(--fourth-border-color);
}

.error {
    text-align: left;
    font-size: 13px;
    padding: 2px 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--secondar-font-color);
    background: var(--danger-primary-color);
}

.error:first-letter {
    text-transform: capitalize;
}

.inputHolder {
    width: 100%;
    margin-bottom: .8em;
}

.noMargin {
    margin-bottom: 0;
}

.checkHolder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.label {
    display: flex;
    text-align: left;
    font-size: 0.7em;
    font-weight: 500;
    padding-bottom: 3px;
    color: var(--secondary-border-color);
    font-family: var(--primary-font-family);
}

.checkLabel {
    font-size: 1em;
    font-weight: 800;
    padding-right: .4em;
    color: var(--secondary-border-color);
    font-family: var(--primary-font-family);
}

.focused {
    color: var(--primary-font-color);
}

.formInput {
    width: 100%;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 16px;
    line-height: 24px;
    border-radius: 5px;
    min-height: 38px;
    max-height: 38px;
    box-sizing: border-box;
    color: var(--primary-font-color);
    transition: border-color .25s ease-in-out;
    border: 1px solid var(--primary-border-color);
}

.formInput:disabled {
    background-color: var(--primary-border-color);
}

.formInput:focus {
    border: 1px solid var(--primary-font-color);
}

.inputError {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid var(--danger-primary-color);
}

.formInput:focus.inputError {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid var(--danger-primary-color);
}

.formInput::-webkit-input-placeholder {
    font-weight: 300;
    font-family: "Nunito Sans", sans-serif;
}

.formSelectWrapper {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
}

.iconHolder {
    padding-right: 0.4em;
}


.modalPreventBlurHolder {
    flex: 1;
    display: flex;
    overflow: auto;
    max-height: 375px;
    flex-direction: column;
}
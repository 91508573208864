
.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--fourth-container-background-color);
}

.calendar {
    height: 100vh;
}

.calendarWrapper {
    width: 100%;
    height: calc(100vh - 68px - var(--navbar-height) - 10px);
}

.timeLabel {
    width: 50px;
    text-align: center;
}

.row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
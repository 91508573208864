.label {
    font-size: 0.9em;
    font-weight: 700;
    padding-left: 0.3em;
    padding-bottom: .3em;
    color: var(--primary-font-color);
    font-family: var(--primary-font-family);
}

.wrapper div {
    cursor: pointer;
}
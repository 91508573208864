.container {
    display: flex;
    padding: 25px 30px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background: var(--fourth-container-background-color);
}

.loader {
    color: var(--primary-font-color);
}

.iconHolder {
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: var(--danger-primary-color);
}

.icon {
    color: var(--secondar-font-color);;
}

.iconWrapper {
    top: 0;
    padding: 10px;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    background: var(--fourth-container-background-color);
}

.content {
    width: 450px;
    display: flex;
    margin-top: 30px;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 1.3em;
    font-weight: 800;
    margin-bottom: 15px;
    color: var(--primary-font-color);
}

.description {
    font-size: .9em;
    margin-top: 5px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 15px;
    color: var(--primary-font-color);
}

.apiError{
    font-size: 1.2em;
    font-weight: 800;
    padding-top: 10px;
}

.footer {
    width: 90%;
    margin-top: 30px;
}

@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,700&display=swap);

.guest-page_main__cydDr{
    width: 100vw;
    height: 100vh;
    display: flex;
    margin-top: -150px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.sign-in_wrapper__1zAwx {
    width: 448px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    padding: 24px 48px 40px;
    background: var(--fourth-container-background-color);
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 1px 3px 1px;
}

.sign-in_formContent__C6iSZ {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.sign-in_title__2nzuL {
    font-size: 28px;
    font-weight: 800;
    line-height: 42px;
    color: var(--primary-font-color);
}
.input_error__1xDKo {
    text-align: left;
    font-size: 13px;
    padding: 2px 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: var(--secondar-font-color);
    background: var(--danger-primary-color);
}

.input_error__1xDKo:first-letter {
    text-transform: capitalize;
}

.input_inputHolder__29Bqc {
    width: 100%;
    margin-bottom: .8em;
}

.input_noMargin__11cj0 {
    margin-bottom: 0;
}

.input_checkHolder__nsqeS {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.input_label__12ZA1 {
    display: flex;
    text-align: left;
    font-size: 0.7em;
    font-weight: 500;
    padding-bottom: 3px;
    color: var(--secondary-border-color);
    font-family: var(--primary-font-family);
}

.input_checkLabel__K_s15 {
    font-size: 1em;
    font-weight: 800;
    padding-right: .4em;
    color: var(--secondary-border-color);
    font-family: var(--primary-font-family);
}

.input_focused__kzVU- {
    color: var(--primary-font-color);
}

.input_formInput__25Fzo {
    width: 100%;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 16px;
    line-height: 24px;
    border-radius: 5px;
    min-height: 38px;
    max-height: 38px;
    box-sizing: border-box;
    color: var(--primary-font-color);
    transition: border-color .25s ease-in-out;
    border: 1px solid var(--primary-border-color);
}

.input_formInput__25Fzo:disabled {
    background-color: var(--primary-border-color);
}

.input_formInput__25Fzo:focus {
    border: 1px solid var(--primary-font-color);
}

.input_inputError__248vw {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid var(--danger-primary-color);
}

.input_formInput__25Fzo:focus.input_inputError__248vw {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid var(--danger-primary-color);
}

.input_formInput__25Fzo::-webkit-input-placeholder {
    font-weight: 300;
    font-family: "Nunito Sans", sans-serif;
}

.input_formSelectWrapper__CmFK1 {
    display: flex;
    flex: 1 1;
    align-items: center;
    flex-direction: row;
}

.input_iconHolder__2NaLa {
    padding-right: 0.4em;
}


.input_modalPreventBlurHolder__t3Qbc {
    flex: 1 1;
    display: flex;
    overflow: auto;
    max-height: 375px;
    flex-direction: column;
}
.buttons_primaryButton__18R5S {
    width: 100%;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--secondar-font-color);
    border: 1px solid var(--secondary-border-color);
    background: var(--secondary-container-background-color);
}

.buttons_outlineButton__3QOxr {
    width: 100%;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--primary-font-color);
    border: 1px solid var(--ternary-border-color);
    background: var(--fourth-container-background-color);
}

.buttons_dangerButton__1_Lhk {
    width: 100%;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--secondar-font-color);
    background: var(--danger-primary-color);
    border: 1px solid var(--danger-primary-color);
}

.buttons_flatButton__38hqo {
    width: 100%;
    border: none;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--primary-font-color);
    background: var(--fourth-container-background-color);
}


.loaders_container__1xvLu {
    display: flex;
    padding: 25px 30px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background: var(--fourth-container-background-color);
}

.loaders_loader__3SQtY {
    color: var(--primary-font-color);
}

.loaders_iconHolder__3O7hl {
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: var(--danger-primary-color);
}

.loaders_icon__3Sqg6 {
    color: var(--secondar-font-color);;
}

.loaders_iconWrapper__3Y9si {
    top: 0;
    padding: 10px;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    background: var(--fourth-container-background-color);
}

.loaders_content__2q88P {
    width: 450px;
    display: flex;
    margin-top: 30px;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.loaders_title__3obYt {
    font-size: 1.3em;
    font-weight: 800;
    margin-bottom: 15px;
    color: var(--primary-font-color);
}

.loaders_description__1uLcq {
    font-size: .9em;
    margin-top: 5px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 15px;
    color: var(--primary-font-color);
}

.loaders_apiError__1bFbl{
    font-size: 1.2em;
    font-weight: 800;
    padding-top: 10px;
}

.loaders_footer__1EPjM {
    width: 90%;
    margin-top: 30px;
}

.dashboard_applicationWrapper__xKY_-{
    display: flex;
    flex-direction: column;
}
.sidebar_sidebar__nqVcl {
    z-index: 10;
    height: 100%;
    position: fixed;
    transition: all 0.5s;
    width: var(--sidebar-width);
    max-width: var(--sidebar-width);
    min-width: var(--sidebar-width);
    background: var(--ternary-container-background-color);
}

.sidebar_isOpen__31ei8 {
    margin-left: 0;
    min-width: 275px;
    max-width: 275px;
    transition: 0.5s;
}

.sidebar_sideMenu__3VDqz {
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}

.sidebar_sideMenu__3VDqz::-webkit-scrollbar {
    width: 0;
}

.sidebar_sideMenu__3VDqz::-webkit-scrollbar-thumb {
    border-radius: 3px;
}

.sidebar_sideMenu__3VDqz::-webkit-scrollbar-thumb:hover {
}

.sidebar_header__10m6U {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /*height: calc(var(--navbar-height) - 10px);*/
}

.sidebar_separator__WDvP4 {
    width: 50px;
    height: 1px;
    opacity: .3;
    background: var(--secondary-border-color);
}

.sidebar_content__2YNsF {
    padding-top: 30px;
}

.sidebar_logo__2uEKb {
    cursor: pointer;
}

.sidebar_footer__1pard {
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    cursor: pointer;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.sidebar_modalFooter__2cHGO {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sidebar_left__RJPyT {
    display: flex;
    align-items: center;
}

.sidebar_currentUserInfo__2TFCN {
    display: flex;
    padding-left: 16px;
    flex-direction: column;
}

.sidebar_currentUserName__3U7X7 {
    font-weight: 600;
    text-transform: capitalize;
    color: var(--fourth-border-color);
}

.sidebar_currentLabel__WxQTC {
    font-size: 10px;
    line-height: 1.8em;
    text-transform: uppercase;
    color: var(--fourth-border-color);
}


.avatar_avatar__B9DQm {
    object-fit: cover;
    background: linear-gradient(-135deg, #eee, #d8d8d8);
}

.modal_headerIcon__12CI0 {
    cursor: pointer;
    color: var(--primary-font-color);
}

.modal_content__8eHvb {
    width: 450px;
    display: flex;
    overflow-y: auto;
    text-align: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--fourth-container-background-color);
}

.modal_content__8eHvb::-webkit-scrollbar {
    width: 5px;
}

.modal_content__8eHvb::-webkit-scrollbar-track {
    background: transparent;
}

.modal_content__8eHvb::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--ternary-border-color);
}

.modal_content__8eHvb::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-border-color);

}

.modal_simpleContent__3sTog {
    width: 433px;
    padding: 24px;
    border-radius: 8px;
    background: var(--fourth-container-background-color);
}

.modal_header__1KqpG {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
}

.modal_headerTitle__3vgEC {
    font-size: 28px;
    font-weight: 700;
    padding-left: 16px;
    color: var(--primary-font-color);
}

.modal_footer__1YdiL {
    padding: 24px 0 0 0;
    border-top: 1px solid var(--primary-border-color);
}


/**
    Popups with icons
 */

.modal_modalWithIcon__16ejV {
    display: flex;
    padding: 25px 30px;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    background: var(--fourth-container-background-color);
}

.modal_removeTop__3HqOh {
    padding-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}


.modal_iconHolder__1faaa {
    width: 75px;
    height: 75px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: var(--danger-primary-color);
}

.modal_icon__2YUIu {
    color: var(--secondar-font-color);;
}

.modal_imgStyle__3TcUc {
    object-fit: contain;
}

.modal_iconWrapper__3OO32 {
    top: 0;
    padding: 10px;
    overflow: hidden;
    position: absolute;
    border-radius: 50%;
    background: var(--fourth-container-background-color);
}

.modal_title__usOxL {
    font-size: 1.3em;
    font-weight: 800;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 15px;
    text-transform: capitalize;
    color: var(--primary-font-color);
}

.modal_titleBottom__3Sbm5 {
    width: 100%;
    padding-bottom: 1em;
    border-bottom: 1px solid var(--primary-border-color);
}

.modal_description__FuOOm {
    font-size: .9em;
    margin-top: 5px;
    font-weight: 600;
    line-height: 1.2em;
    margin-bottom: 15px;
    color: var(--primary-font-color);
}


.modal_footer__1YdiL {
    width: 100%;
    margin-top: 30px;
}

.modal_footerRow__3TSza {
    display: flex;
    flex-direction: row;
}


.modal_altHeader__1BlsW {
    display: flex;
    padding: 15px;
    font-size: 1.2em;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: var(--secondar-font-color);
    font-family: var(--primary-font-family);
    background-color: var(--primary-font-color);
}
.menu_itemHolder__2jlTU {
    height: 36px;
    display: flex;
    color: #7f8084;
    padding: 8px 0;
    align-items: center;
    justify-content: center;
    border-left: 3px solid transparent;
}

.menu_active__3xaek {
    color: var(--secondar-font-color);
    border-left: 3px solid var(--secondar-font-color);
}

.main_content_main__GKdFK {
    display: flex;
    padding-top: 10px;
    padding-left: 70px;
    height: calc(100vh - var(--navbar-height) - 10px);
    background-color: var(--fourth-container-background-color);
}
.event_eventContainer__3PLX- {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    margin-top: 1px;
    padding-top: 3px;
    padding-left: 5px;
    border-radius: 6px;
    border-left: 5px solid;
}

.event_eventContainer__3PLX-.event_shadow__3289B {
    padding-top: 5px !important;
    padding-left: 10px !important;
    border-left: 5px solid var(--primary-font-color) !important;
    /*box-shadow: rgba(14, 30, 37, 0.12) 0 2px 4px 0, rgba(14, 30, 37, 0.32) 0 2px 16px 0;*/
}

.event_infoWrapper___B4cx {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex-direction: column;
    text-overflow: ellipsis;
}

.event_timeText__1q1co {
    display: flex;
    font-size: 14px;
    font-weight: 800;
    padding-right: 5px;
    color: var(--primary-font-color);
}

.event_titleText__13jHq {
    font-size: 13px;
    font-weight: 600;
    padding-top: 0.06em;
    text-overflow: ellipsis;
    color: var(--primary-font-color);
}

.event_bullet__R_Vcp {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 6px !important;
}

.event_allDayTimeText__1l_qw {
    display: flex;
    font-size: 14px;
    font-weight: 800;
    padding-right: 5px;
    color: var(--danger-primary-color);
}

.event_indicatorHolder__2epjB {
    bottom: 0;
    right: 5px;
    position: absolute;
}

.event_noteIndicatorHolder__2yfbL {
    top: 5px;
    right: 5px;
    position: absolute;
}
.resource_resourceHolder__3wFCG {
    display: flex;
    cursor: pointer;
    padding-top: 10px;
    align-items: center;
    padding-bottom: 10px;
    align-self: flex-start;
}

.resource_infoHolder__2uNql {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.resource_resourceTitle__2DdOy {
    font-size: 1em;
    font-weight: 700;
    color: var(--primary-font-color);
}

.resource_workingHours__19a4n {
    font-size: 14px;
    font-weight: 800;
    color: var(--secondary-border-color);
}

.resource_resourceWorkingHoursHolder__3EyA4 {
    display: flex;
    flex-direction: row;
}

.resource_noTimeLabel__29ALu {
    font-size: 14px;
    font-weight: 800;
    line-height: 1.5714em;
    color: var(--secondary-border-color);
}

.resource_label__31clu{
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
    line-height: 1.5714em;
    color: var(--secondary-border-color);
}
.pause_container_selectedTimeRow__wqVcP {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pause_container_startTimeSelect__OJNdQ {
    flex: 1 1;
    margin-right: .5em;
}

.pause_container_endTimeSelect__12VXJ {
    flex: 1 1;
    margin-left: .5em;
}

.tekica-select_label__3976r {
    font-size: 0.9em;
    font-weight: 700;
    padding-left: 0.3em;
    padding-bottom: .3em;
    color: var(--primary-font-color);
    font-family: var(--primary-font-family);
}

.tekica-select_wrapper__2wvEv div {
    cursor: pointer;
}
.popup_baseModalHolder__VbEKE {
    width: 500px;
    border-radius: 17px;
    background: var(--fourth-container-background-color);
}

.popup_contentHolder__2PxI4 {

}

.popup_baseModalHeader__2XYOF {
    display: flex;
    padding-top: 1.8em;
    position: relative;
    align-items: center;
    padding-bottom: 1.3em;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
}

.popup_baseModalFooter__jK1yM {
    flex: 1 1;
    width: 100%;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    flex-direction: column;
}

.popup_baseModalFooterWrapper__xt5KA {
    padding-left: .8em;
    padding-top: 1em;
    padding-right: .8em;
    border-top: 1px solid var(--primary-border-color);
}


.popup_baseModalAvatarHolder__dvrgd {
    z-index: 1;
    top: -50px;
    width: 80px;
    height: 80px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    background: inherit;
    align-items: center;
    justify-content: center;
}

.popup_baseModalHeaderSubTitle__3OI7b {
    z-index: 2;
    font-size: 1em;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--secondar-font-color);
}

.popup_baseModalHeaderTitle__QZRVy {
    margin-top: -3px;
    font-weight: 800;
    font-size: 1.5em;
    color: var(--secondar-font-color);
}

.popup_textBottom__9ZSg4 {
    bottom: 5px;
    right: 15px;
    font-weight: 600;
    font-size: 0.75em;
    position: absolute;
    text-transform: capitalize;
    color: var(--secondar-font-color);
}

.popup_popupContainer__1qNc0 {
    flex: 1 1;
    display: flex;
    padding-left: .8em;
    padding-right: .8em;
    flex-direction: column;
}

.popup_popupContentHolder__rc6Fj {
    padding-top: 1em;
}

.popup_popupContentWrapper__X9RQR {
    padding-top: .8em;
    padding-left: .8em;
    padding-right: .8em;
}

.popup_popupContentDivider__ru98- {
    font-size: 1.1em;
    font-weight: 800;
    padding-top: .15em;
    padding-bottom: .15em;
    color: var(--secondary-border-color);
    background: var(--primary-border-color);
}

.popup_hideDivider__3uAu8 {
    display: none;
}


.popup_popupFooterDefaultRow__1fDVu {
    display: flex;
    justify-content: flex-end;
}

.popup_popupFooterDefaultRowWrapper__1h2GD {
    display: flex;
}



.appointment_list_content__2uHOn {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    background-color: var(--fourth-container-background-color);
}

.appointment_list_calendar__7eQUW {
    height: 100vh;
}

.appointment_list_calendarWrapper__1eD1C {
    width: 100%;
    height: calc(100vh - 68px - var(--navbar-height) - 10px);
}

.appointment_list_timeLabel__3C5Lf {
    width: 50px;
    text-align: center;
}

.appointment_list_row__dd7uz {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.header_toolbar_holder__1KSJo {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    padding: 10px 10px 10px 50px;
    /*border-bottom: 1px solid var(--primary-border-color);*/
    background: var(--fourth-container-background-color);
}


.header_toolbar_toolbarButton__2oIhF {
    cursor: pointer;
    min-width: 48px;
    min-height: 48px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 8px;
    border: 1px solid var(--ternary-border-color) !important;
    background: var(--fourth-container-background-color) !important;
}

.header_toolbar_selectedDateText__19ef1 {
    font-size: 15px;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: capitalize;
    color: var(--primary-font-color);
}

.header_toolbar_calendarHolder__2HcM3 {
    top: 70px;
    display: flex;
    z-index: 9999;
    position: absolute;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    background-color: var(--fourth-container-background-color);
}

.header_toolbar_calendarHolder__2HcM3:before {
    content: "";
    left: 0;
    width: 0;
    right: 0;
    height: 0;
    top: -12px;
    margin: auto;
    position: absolute;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent var(--fourth-container-background-color) transparent;
}
.calendar_container__14Qzo {
    padding: 10px 60px 40px;
    position: relative;
}

.calendar_separator__1og8r {
    width: 100%;
    height: 67px;
    border-bottom: 1px solid #dddddd;
}

.calendar_hidden__14Pkm {
    display: none;
}

.calendar_calendar__14iP- {
    border: none;
}

.calendar_navButtons__ZSx7f {
    display: flex;
    cursor: pointer;
    min-width: 40px;
    min-height: 40px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    /*border: 1px solid var(--ternary-border-color) !important;*/
    background: var(--fourth-container-background-color) !important;
}

.calendar_verticalSeparator__3p607{
    right: -1px;
    top: 67px;
    bottom: 0;
    width: 1px;
    z-index: 1;
    position: absolute;
    background-color: #dddddd;
}


.select-client_selectClientContainer__2Zsff {
    display: flex;
    cursor: pointer;
    padding-top: .7em;
    flex-direction: row;
    align-items: center;
    padding-bottom: .7em;
}

.select-client_clientInfoHolder___DxHi {
    flex: 1 1;
    padding-left: .5em;
}

.select-client_clientName__3sRRp {
    font-weight: 800;
    text-transform: capitalize;
}

.select-client_clientPhoneNumber__1yCyf {
    font-size: 0.9em;
    padding-top: .2em;
    color: var(--secondary-border-color);
}

.select-client_leftSide__3Ni4Q {
    flex: 1 1;
    display: flex;
    flex-direction: row;
}

.select-client_rightSide__17qlt {
    padding-right: 1em;
}

.select-client_arrowRight__3krKG {
    padding-left: .5em;
}

.select-client_footer__38rrN {
    padding-top: 2em;
}

.scroll-holder_infinityScrollHolder__1D1Uc {
    flex: 1 1;
    display: flex;
    overflow: auto;
    margin-left: 1em;
    max-height: 375px;
    margin-right: 1em;
    padding-top: 1em;
    flex-direction: column;
}

.service-selector_selectServiceContainer__3XH1- {
    display: flex;
    cursor: pointer;
    padding-top: .7em;
    flex-direction: row;
    align-items: center;
    padding-bottom: .7em;
}

.service-selector_serviceInfoHolder__3Fw9T {
    flex: 1 1;
    padding-left: .5em;
}

.service-selector_serviceName__g0n4t {
    font-weight: 600;
    overflow: hidden;
    max-width: 330px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.service-selector_serviceDuration__1UJzL {
    font-size: 0.9em;
    padding-top: .2em;
    color: var(--secondary-border-color);
}

.service-selector_leftSide__2hnra {
    flex: 1 1;
    display: flex;
    flex-direction: row;
}

.service-selector_rightSide__3qVBe {
    padding-right: 1em;
}

.service-selector_arrowRight__1vxYn {
    padding-left: .5em;
}

.service-selector_footer__3TSZ3{
    padding-top: 2em;
}
.appointment-upsert_appointmentContainer__16qH4 {
    width: 500px;
    border-radius: 17px;
    background: var(--fourth-container-background-color);
}

.appointment-upsert_appointmentContainerLoader__1NBo- {
    flex: 1 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appointment-upsert_appointmentHeader__1JC5U {
    display: flex;
    padding-top: 1.8em;
    position: relative;
    align-items: center;
    padding-bottom: 1.3em;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
}

.appointment-upsert_appointmentHeaderConfirmed__2MVMZ {
    background: var(--status-confirmed-color);
}

.appointment-upsert_appointmentHeaderDone___RoFd {
    background: var(--secondary-border-color);
}

.appointment-upsert_appointmentHeaderNoShow__3q2TC,
.appointment-upsert_appointmentHeaderCanceled__vxlP3,
.appointment-upsert_appointmentHeaderDeclined__1__ny,
.appointment-upsert_appointmentHeaderClientCanceled__XFX2- {
    background: var(--status-cancel-color);
}

.appointment-upsert_appointmentHeaderNeedApproval__rgLT8 {
    background: var(--status-need-approval-color);
}

.appointment-upsert_appointmentHeaderStatus__3-Dvq {
    z-index: 2;
    font-weight: 800;
    font-size: 1em;
    text-transform: uppercase;
    color: var(--secondar-font-color);
}

.appointment-upsert_appointmentHeaderClientName__3C59I {
    margin-top: -3px;
    font-weight: 800;
    font-size: 1.5em;
    color: var(--secondar-font-color);
}

.appointment-upsert_appointmentHeaderBookingStatusId__1f0wK {
    bottom: 5px;
    right: 15px;
    font-weight: 600;
    font-size: 0.75em;
    position: absolute;
    text-transform: capitalize;
    color: var(--secondar-font-color);
}

.appointment-upsert_appointmentClientAvatarHolder__3MD6b {
    z-index: 1;
    top: -50px;
    width: 80px;
    height: 80px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    background: inherit;
    align-items: center;
    justify-content: center;
}

.appointment-upsert_appointmentActionsContainer__27_Rj {
    display: flex;
    padding-top: .5em;
    align-items: center;
    justify-content: center;
}

.appointment-upsert_appointmentActionItemContainer__19TR_ {
    display: flex;
    padding-left: 1.5em;
    padding-right: 1.5em;
    justify-content: center;
}

.appointment-upsert_appointmentActionItem__1Zg3d {
    width: 35px;
    height: 35px;
    display: flex;
    cursor: pointer;
    font-size: 1.3em;
    position: relative;
    border-radius: 50%;
    overflow: visible;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: var(--secondar-font-color);
    border: 1px solid var(--secondary-border-color);
}

.appointment-upsert_appointmentActionItemTitle__CgwDU {
    bottom: -1.8em;
    font-weight: 800;
    position: absolute;
    font-size: 0.7em !important;
}

.appointment-upsert_appointmentChargeItem__2uBZ3 {
    color: var(--utility-green);
    border: 1px solid var(--utility-green);
}

.appointment-upsert_appointmentCancelItem__f0WVH {
    color: var(--primary-font-color);
}

.appointment-upsert_appointmentNoShowItem__mfCzM {
    color: var(--status-cancel-color);
    border: 1px solid var(--status-cancel-color);
}

.appointment-upsert_appointmentChargeItem__2uBZ3:hover {
    color: var(--secondar-font-color);
    background: var(--utility-green);
}

.appointment-upsert_appointmentNoShowItem__mfCzM:hover {
    color: var(--secondar-font-color);
    background: var(--status-cancel-color);
}

.appointment-upsert_appointmentCancelItem__f0WVH:hover {
    color: var(--secondar-font-color);
    background: var(--primary-font-color);
}

.appointment-upsert_appointmentCancelItem__f0WVH:hover .appointment-upsert_appointmentActionItemTitle__CgwDU {
    color: var(--primary-font-color);
}

.appointment-upsert_appointmentChargeItem__2uBZ3:hover .appointment-upsert_appointmentActionItemTitle__CgwDU {
    color: var(--utility-green);
}

.appointment-upsert_appointmentNoShowItem__mfCzM:hover .appointment-upsert_appointmentActionItemTitle__CgwDU {
    color: var(--status-cancel-color)
}

.appointment-upsert_disabledActions__1a-RZ {
    color: var(--secondar-font-color);
    background: var(--secondary-border-color);
    border-color: var(--secondary-border-color);
}

.appointment-upsert_disabledActions__1a-RZ:hover {
    background: var(--secondary-border-color);
    border-color: var(--secondary-border-color);
}

.appointment-upsert_disabledActions__1a-RZ .appointment-upsert_appointmentActionItemTitle__CgwDU {
    color: var(--secondary-border-color);
}

.appointment-upsert_disabledActions__1a-RZ:hover .appointment-upsert_appointmentActionItemTitle__CgwDU {
    color: var(--secondary-border-color);
}

.appointment-upsert_clientInfoContainer__1A-Vu {
    padding-top: 2em;
}

.appointment-upsert_clientUpsertInfoContainer__QytAX {
    padding-top: .5em;
}

.appointment-upsert_clientInfoWrapper__1V9NZ {
    padding-top: .5em;
    padding-left: .8em;
    padding-right: .8em;
}

.appointment-upsert_divider__3bbfV {
    font-size: 1.1em;
    font-weight: 800;
    padding-top: .15em;
    padding-left: .7em;
    padding-bottom: .15em;
    color: var(--secondary-border-color);
    background: var(--primary-border-color);
}

.appointment-upsert_clientInfoItem__11TEa {
    margin-top: 1em;
    display: flex;
    font-size: 1em;
    font-weight: 700;
    align-items: center;
    color: var(--secondary-border-color);
}

.appointment-upsert_clientInfoItem__11TEa:first-child {
    margin-top: 0;
}

.appointment-upsert_clientInfoItemName__327b5 {
    padding-left: .5em;
    color: var(--secondary-border-color)
}

.appointment-upsert_clientInfoItemName__327b5::first-letter {
    text-transform: uppercase;
}

.appointment-upsert_phoneRotate__1vKdg {
    transform: rotate(90deg);
}

.appointment-upsert_selectClientButton__3jDuP {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    border-radius: .3em;
    padding: .5em .5em;
    border: 1px dashed var(--ternary-border-color);
}

.appointment-upsert_selectServiceButton__3xZ8b {
    display: flex;
    width: 100%;
    cursor: pointer;
    position: relative;
    border-radius: .3em;
    padding: .5em .5em;
    flex-direction: column;
}

.appointment-upsert_selectClientButtonText__1TCca {
    font-size: 1em;
    font-weight: 600;
    padding-left: .5em;
    color: var(--secondary-border-color);
}

.appointment-upsert_clientUpsertInfoHolder__HHsl2 {
    flex: 1 1;
    padding-left: .5em;
}

.appointment-upsert_clientUpsertName__3Urwb {
    font-size: 1em;
    font-weight: 800;
    text-transform: capitalize;
    color: var(--primary-font-color);
}

.appointment-upsert_clientUpsertPhoneNumber__2oifT {
    font-size: .8em;
    padding-top: .15em;
    color: var(--secondary-border-color);
}

.appointment-upsert_walkInCheck__2UA5I {
    padding-top: 1em;
}

.appointment-upsert_selectClientArrowRight__1nuNF {
    right: .8em;
    position: absolute;
}

.appointment-upsert_selectServiceArrowRight__Rkm3K {
    top: 25%;
    right: 1em;
    position: absolute;
}

.appointment-upsert_verified__2ADK6 {
    right: 3em;
    position: absolute;
}

.appointment-upsert_servicesContainer__2bfc5 {
    padding-top: 1em;
}

.appointment-upsert_servicesContainerWrapper__1tSje {
    display: flex;
    padding-top: .5em;
    padding-left: 1em;
    padding-right: .8em;
}

.appointment-upsert_serviceContainerFirstItemWrapper__3GBSk {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    padding-right: 1.2em;
    justify-content: space-between;
}

.appointment-upsert_serviceContainerButtonFirstItemWrapper__2gwow {
    flex: 1 1;
    display: flex;
    cursor: pointer;
    position: relative;
    margin-left: .15em;
    border-radius: 5px;
    flex-direction: row;
    padding-right: 1.2em;
    justify-content: space-between;
    border: 1px dashed var(--ternary-border-color);
}

.appointment-upsert_serviceContainerFirstItem__3ZW9N {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.appointment-upsert_serviceInfoWrapper__3jqsQ {
    padding-left: 10px;
}

.appointment-upsert_servicesTitle__2vTgK {
    font-size: 1em;
    font-weight: 700;
    color: var(--primary-font-color);
}

.appointment-upsert_serviceTime__6ZIWC {
    font-size: .9em;
    font-weight: 700;
    margin-top: -1px;
    color: var(--secondary-border-color);
}

.appointment-upsert_employeeName__3N4nT {
    font-size: .95em;
    font-weight: 700;
    margin-top: -1px;
    color: var(--secondary-border-color);
}

.appointment-upsert_firstServicePrice__mwXV_ {
    font-size: 1em;
    font-weight: 700;
    align-self: flex-end;
    padding-bottom: .3em;
    color: var(--primary-font-color);
}


.appointment-upsert_serviceContainerTotalHolder__28rWq {
    display: flex;
    margin-top: 1em;
    padding-top: .5em;
    padding-right: 2em;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--primary-border-color);
}

.appointment-upsert_serviceContainerTotalPlaceholder__16vLl {
    font-size: 1em;
    font-weight: 700;
    color: var(--secondary-border-color);
}

.appointment-upsert_serviceContainerTotalPrice__HxjJK {
    font-weight: 800;
    font-size: 1em;
    color: var(--secondary-tint-color);
}

.appointment-upsert_appointmentNotesContainer__wxhw9 {
    padding-top: 1em;
}

.appointment-upsert_appointmentNotesWrapper__3P34q {
    padding-top: .5em;
    padding-left: .8em;
    padding-right: .8em;
}

.appointment-upsert_appointmentUpsertFooter__1VyG5 {
    display: flex;
    margin-top: 1em;
    padding: 1em .8em;
    justify-content: flex-end;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    border-top: 1px solid var(--primary-border-color);
}

.appointment-upsert_upsertButtonHolder__DMUFy {
    display: flex;
    flex-direction: row;
}

.appointment-upsert_cancelBtnMargin__BQ0Mf {
    margin-right: 1em;
    font-weight: 600;
    color: var(--secondary-border-color);
}

.appointment-upsert_submitCancelBtn__1Cu1v {
    min-width: 10em;
}

.appointment-upsert_cancelCheckBox__3ZTK7 {
    padding-top: 1em;
    justify-content: space-between;
}

.appointment-upsert_cancelCheckBox__3ZTK7:last-child {
    padding-bottom: 2em;
}

.appointment-upsert_footer__2fqYB {
}
.date-item_container__37qAc {
}

.date-item_containerWrapper__221C_ {
    display: flex;
    padding-left: 10px;
    border-radius: 5px;
    padding-right: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--ternary-border-color);
    border-left: 4px solid var(--ternary-border-color);
}

.date-item_title__1vHpH {
    font-size: 1em;
    font-weight: 800;
    color: var(--secondary-tint-color);
}

.date-item_day__2MH2x {
    margin-top: -2px;
    font-weight: 800;
}

.date-item_time__zdUiR {
    margin-top: -2px;
    font-weight: 800;
    color: var(--secondary-border-color);
}
.work_schedule_container__cUQyn {
    width: 100%;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
}

.work_schedule_workScheduleContainer__19faL {
    display: flex;
    border-radius: 0 0 10px 10px;
    border: 1px solid var(--primary-border-color);
}

.work_schedule_weekDisplay__3rYww {
    display: flex;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--secondar-font-color);
    background-color: var(--primary-font-color);
}

.work_schedule_dayDisplay__28WYI {
    flex: 1 1;
    font-size: 1em;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: capitalize;
    border-right: 1px solid var(--primary-border-color);
}

.work_schedule_dayDisplay__28WYI:last-child {
    border-right: none;
}

.work_schedule_dayDisplay__28WYI:first-child {
    border-left: 1px solid var(--primary-border-color);
}

.work_schedule_resourceHolder__16BEh {
    width: 230px;
    display: flex;
    max-width: 230px;
    flex-direction: column;
}

.work_schedule_resourceItem__1lYm8 {
    flex: 1 1;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    text-overflow: ellipsis;
    border-right: 1px solid var(--primary-border-color);
    border-bottom: 1px solid var(--primary-border-color);
}

.work_schedule_resourceItemWrapper__1p7Da {
    display: flex;
    padding-left: 15px;
    align-items: center;
}

.work_schedule_resourceItem__1lYm8:last-child {
    border-bottom: none;
}

.work_schedule_resourceTitle__2DWIS {
    font-size: 1em;
    font-weight: 600;
    padding-left: 10px;
    color: var(--secondary-border-color);
}

.work_schedule_weekHolder__ROajB {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}

.work_schedule_weekRow__9dGS_ {
    display: flex;
}

.work_schedule_weekItem__1lcdt {
    flex: 1 1;
    display: flex;
    cursor: pointer;
    margin-bottom: -0.30px;
    color: var(--secondary-border-color);
    border-bottom: 1px solid var(--primary-border-color);
    border-right: 1px solid var(--primary-border-color);
}

.work_schedule_itemWrapper__1sVfU {
    flex: 1 1;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.work_schedule_weekRow__9dGS_:last-child .work_schedule_resourceItem__1lYm8 {
    border-bottom: none;
}

.work_schedule_weekRow__9dGS_:last-child .work_schedule_weekItem__1lcdt {
    border-bottom: none;
}

.work_schedule_weekItem__1lcdt:last-child {
    border-right: none;
}

.work_schedule_shiftItem__2R2DY {
    flex: 1 1;
    width: 100%;
    display: flex;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 5px;
    border-radius: 5px;
    padding-top: 10px;
    align-items: center;
    padding-bottom: 10px;
    justify-content: center;
    color: var(--secondar-font-color);
}

.work_schedule_shiftItem__2R2DY:hover {
    background-image: none;
    background-color: var(--primary-tint-color) !important;
}

.work_schedule_shiftItem__2R2DY:hover .work_schedule_standardTimeText__ss7rS {
    color: var(--primary-font-color);
}

.work_schedule_shiftItem__2R2DY:hover .work_schedule_shiftText__JoMW8 {
    opacity: 1;
    color: var(--primary-font-color);
}


.work_schedule_shiftItem__2R2DY:hover .work_schedule_nonWorking__3E86Q {
    color: var(--primary-font-color);
}

.work_schedule_shiftItem__2R2DY:last-child {
    margin-bottom: 0;
}

.work_schedule_nonWorking__3E86Q {
    background-size: 20px 20px;
    color: var(--secondary-border-color);
    background-color: rgba(215, 215, 215, 0.4);
    background-image: url("https://res.cloudinary.com/barbershop-branding/image/upload/v1632544843/Tekica%20Web/calendar-pattern_mrqu5y.svg");
}

.work_schedule_standardTime__3l31E {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-blue-100);
}

.work_schedule_standardTimeText__ss7rS {
    font-size: 0.9em;
    color: var(--secondary-border-color);
}

.work_schedule_shiftText__JoMW8 {
    opacity: 0.8;
    color: inherit;
    font-size: 0.9em;
}

.work_schedule_defaultItem__1vMPb {
    flex-direction: column;
    color: var(--secondar-font-color);
    background: var(--utility-blue-100) default;
}

.work_schedule_firstItem__owRv6 {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-brown);
}

.work_schedule_secondItem__hIbKj {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-green);
}

.work_schedule_thirdItem__3c3E7 {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-brown);
}

.work_schedule_upsertContainer__1EJBM {
    flex: 1 1;
    width: 100%;
    padding-top: 1em;
}

.work_schedule_upsertContainerRow__1bGOH {
    display: flex;
    padding-left: 2px;
    margin-bottom: 1em;
}

.work_schedule_upsertContainerRow__1bGOH:last-child {
    margin-bottom: 0;
}

.work_schedule_upsertContainerItem__3cmJL {
    flex: 1 1;
    text-align: left;
    margin-right: 1em;
}

.work_schedule_upsertContainerItem__3cmJL div {
    cursor: pointer;
}

.work_schedule_upsertContainer__1EJBM:last-child {
    margin-right: 0;
}

.work_schedule_upsertContainerFooter__2vboQ {
    display: flex;
    flex-direction: row;
}

.work_schedule_btnSave__13cxQ {
    margin-left: .5em;
}

.work_schedule_btnCancel__yWlGf {
    margin-right: .5em;
}

.work_schedule_addNewSchedule__3bq6R {
    flex: 1 1;
    border: none;
    outline: none;
    display: flex;
    font-size: 1em;
    cursor: pointer;
    background: none;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    color: var(--secondary-tint-color);
}

.work_schedule_addNewSchedule__3bq6R i {
    margin-left: .5em;
}

.work_schedule_repeatRuleContainer__1cpgT {
    display: flex;
    text-align: left;
    padding-top: 1.5em;
}

.work_schedule_repeatRuleContainer__1cpgT:last-child {
    padding-top: 1.5em;
    padding-bottom: 0;
}

.work_schedule_repeatRuleItem__UhRwU {
    flex: 1 1;
}

.work_schedule_repeatRuleItem__UhRwU:first-child {
    margin-right: 1em;
}

.work_schedule_repeatRuleItem__UhRwU:last-child {
    margin-right: 1em;
}

.work_schedule_label__cqGRZ {
    font-size: 0.9em;
    font-weight: 700;
    padding-left: 0.3em;
    padding-bottom: .3em;
    color: var(--primary-font-color);
    font-family: var(--primary-font-family);
}

.work_schedule_repeatDayItem__16vvF {
    /*flex: 1;*/
    cursor: pointer;
    margin-right: 0.7em;
}

.work_schedule_dayItem__22V_k {
    width: 40px;
    height: 40px;
    display: flex;
    font-weight: 700;
    font-size: 0.75em;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: var(--primary-font-color);
    background: var(--primary-border-color);
}

.work_schedule_dayItem__22V_k:hover {
    color: var(--primary-font-color);
    background: var(--primary-tint-color);
}

.work_schedule_selectedDay__26ZMT {
    font-weight: 800;
    color: var(--primary-font-color);
    background: var(--primary-tint-color);
}

.work_schedule_workingIndicator__22mAu{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.work_schedule_workingIndicatorLabel__1V70F{
    font-weight: 700;
    color: var(--secondary-border-color);
}

@media only screen and (max-width: 1024px) {
    .work_schedule_resourceTitle__2DWIS {
        display: none;
    }

    .work_schedule_resourceItemWrapper__1p7Da {
        padding-left: 0;
    }
}

/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');*/

:root {
    --sidebar-width: 70px;
    --navbar-height: 0px;
    --utility-brown: #A04F41;
    --utility-blue: #6485A4;
    --utility-green: #488765;
    --utility-purple: #654887;
    --utility-blue-100: #486A87;
    --utility-blue-200: #9EADBD;
    --primary-tint-color: #ddc686;
    --primary-font-color: #2a2c32;
    --secondar-font-color: #ffffff;
    --secondary-tint-color: #c87533;
    --primary-border-color: #ebebeb;
    --fourth-border-color: #8c8b88;
    --ternary-border-color: #c7cbd4;
    --danger-primary-color: #ec0c48;
    --secondary-border-color: #979797;
    --status-cancel-color: #ec0c48;
    --status-confirmed-color: #00BE70;
    --status-need-approval-color: #f2b624;
    --container-background-color: #ebebeb;
    --full-calendar-time-grid-slot-height: 33px;
    --fourth-container-background-color: #ffffff;
    --ternary-container-background-color: #19191d;
    --secondary-container-background-color: #2a2c32;
    --primary-font-family: "Nunito Sans", sans-serif;
    --appointment-fully-paid-color: rgba(151, 151, 151, .15);
    --appointment-fully-paid-border-color: rgb(151, 151, 151);
}

body {
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Nunito Sans", sans-serif;
    font-family: var(--primary-font-family);
    background: url("https://res.cloudinary.com/barbershop-branding/image/upload/v1632213481/Contigo/contigo_lvjomc.jpg");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
    -webkit-box-shadow: 0 0 0 1000px var(--fourth-container-background-color) inset !important;
}

/**
    Scrollbars
 */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #c7cbd4;
    background: var(--ternary-border-color);
}

::-webkit-scrollbar-thumb:hover {
    background: #979797;
    background: var(--secondary-border-color);
}

/** ------------------------
    Modal styles
 ---------------------------*/

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 1000;
    padding: 24px 0;
    background-color: rgba(0, 0, 0, 0.65) !important;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.fc.fc-media-screen {
    width: 100%;
}

.fc-scrollgrid {
    /*border: none !important;*/
}

.fc-timegrid-slot-label.fc-timegrid-slot-minor {
    border-top: 0 !important;
}

.fc .fc-timegrid-slot-minor {
    border-top-color: rgba(221, 221, 221, .45);
}

.fc-scrollgrid-shrink {
    border: 0 !important;
}

.fc-timegrid-slot-label-cushion {
    font-size: 1em;
    font-weight: 700;
    transform: translateY(-12px);
    color: #979797;
    color: var(--secondary-border-color);
}

.fc-timegrid-axis-cushion {
    color: #979797 !important;
    color: var(--secondary-border-color) !important;
}

.fc .fc-timegrid-col.fc-day-today {
    background-color: transparent !important;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
}

.fc-timegrid-event .fc-event-main {
    padding: 0 !important;
    background: #ffffff;
    background: var(--fourth-container-background-color);
}

.fc-v-event {
    border: none !important;
    background-color: transparent !important;
}

.fc-h-event {
    border: none !important;
    background-color: transparent !important;
}

.fc-non-business {
    background-color: transparent;
    background-size: 25px 25px !important;
    background-image: url("https://res.cloudinary.com/barbershop-branding/image/upload/v1632544843/Tekica%20Web/calendar-pattern_mrqu5y.svg") !important;
}

.fc .fc-highlight {
    background: transparent !important;
}

.fc-scrollgrid {
    border: none !important;
}

.resourceHolder {
    border-right: none !important;
    border-left: none !important;
}

.fc-scrollgrid-section-header {
    /*box-shadow: rgba(50, 50, 105, 0.20) 0 5px 5px 0, rgba(0, 0, 0, 0.05) 0 1px 1px 0;*/
}

.fc-timegrid-axis {
    border-right: none !important;
    /*border-bottom: none !important;*/
}

/*.fc-timegrid-axis-frame {*/
/*    min-height: 67px !important;*/
/*}*/

.fc-scrollgrid-liquid {
    border-top: none !important;
}

.fc-timegrid-axis-frame {
    border-right: none !important;
}

.fc-scrollgrid-section-header > td:first-child {
    border: none !important;
}

.fc-scrollgrid-section-header > td:last-child {
    /*border: none !important;*/
}

.fc-scrollgrid-section-header .fc-scroller {
    overflow-y: hidden !important;
}

.fc-cell-shaded {
    /*display: none;*/
    background: #ffffff !important;
    background: var(--fourth-container-background-color) !important;
    box-shadow: rgba(50, 50, 105, 0.20) 0 5px 5px 0, rgba(0, 0, 0, 0.05) 0 1px 1px 0;
}

.fc-scrollgrid-section-body > td {
    border-bottom: none;
}

.fc-scrollgrid-sync-table .fc-resource {
    border-right: none !important;
    border-left: none !important;
}

.fc-timegrid-slot {
    height: 33px !important;
    height: var(--full-calendar-time-grid-slot-height) !important;
}

.resourceHolder {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.react-calendar__navigation {
    height: 68px !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid #dddddd;
}

.react-calendar__month-view__weekdays {
    align-items: center;
    height: 51px !important;
}

.react-calendar__tile--now {
    border-radius: 8px;
    color: #ffffff !important;
    color: var(--secondar-font-color) !important;
    background-color: #979797;
    background-color: var(--secondary-border-color);
}

.react-calendar__tile {
}

.react-calendar__tile--active {
    border-radius: 8px;
    color: #ffffff !important;
    color: var(--secondar-font-color) !important;
    background-color: #2a2c32 !important;
    background-color: var(--secondary-container-background-color) !important;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    border-radius: 8px;
    font-size: 1.1em !important;
    font-weight: 800 !important;
    text-transform: capitalize !important;
    color: #2a2c32 !important;
    color: var(--primary-font-color) !important;
    font-family: "Nunito Sans", sans-serif !important;
    font-family: var(--primary-font-family) !important;
}

.react-calendar__tile:hover {
    border-radius: 8px;
    color: #ffffff !important;
    color: var(--secondar-font-color) !important;
    background-color: #2a2c32 !important;
    background-color: var(--secondary-container-background-color) !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.7;
    color: #979797 !important;
    color: var(--secondary-border-color) !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent !important;
}

.non-working {
    font-size: 0.8em;
    font-weight: 700;
    color: #ec0c48;
    color: var(--danger-primary-color);
    font-family: "Nunito Sans", sans-serif;
    font-family: var(--primary-font-family);
}

.working-day {
    font-size: 0.8em;
    font-weight: 600;
    color: #2a2c32;
    color: var(--primary-font-color);
    font-family: "Nunito Sans", sans-serif;
    font-family: var(--primary-font-family);
}

.react-calendar__navigation__label {
    background: #ffffff !important;
    background: var(--fourth-container-background-color) !important;
}

.react-calendar__month-view__weekdays__weekday {
    font-weight: 700;
    font-size: 1.1em;
    text-transform: capitalize;
    color: #979797;
    color: var(--secondary-border-color);
    font-family: "Nunito Sans", sans-serif;
    font-family: var(--primary-font-family);
}

/**
    React date picker
 */

.react-datepicker__input-container {
    height: 36px;
    display: flex;
    margin-top: 2px;
    max-height: 38px;
    padding-left: 6px;
    border-radius: 0.4rem;
    border: 1px solid hsl(0, 0%, 70%);
}

.react-datepicker__input-container:after {
    top: 0;
    bottom: 0;
    right: 5px;
    width: 20px;
    height: 20px;
    margin-top: 10px;
    position: absolute;
    font-weight: 900;
    content: "\f133";
    cursor: pointer;
    color: hsl(0, 0%, 80%);
    font-family: "FontAwesome", serif;
}

.react-datepicker__input-container:hover {
    border: 1px solid #2a2c32;
    border: 1px solid var(--primary-font-color);;
}

.react-datepicker__input-container:focus-within {
    border: 1px solid #2a2c32;
    border: 1px solid var(--primary-font-color);
}

.react-datepicker__input-container input {
    width: 95%;
    height: 90%;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0.9em;
    text-transform: capitalize;
}

.react-datepicker__input-container input:disabled {
    background: transparent;
}

.react-datepicker__current-month {
    text-transform: capitalize;
    font-family: "Nunito Sans", sans-serif;
    font-family: var(--primary-font-family);
    color: #ffffff !important;
    color: var(--secondar-font-color) !important;
}

.react-datepicker__header {
    background: #2a2c32 !important;
    background: var(--primary-font-color) !important;
}

.react-datepicker__day-names .react-datepicker__day-name {
    font-size: 0.9em;
    font-weight: 700;
    text-transform: capitalize;
    color: #979797;
    color: var(--secondary-border-color);
    font-family: "Nunito Sans", sans-serif;
    font-family: var(--primary-font-family);
}

.react-datepicker__day--selected {
    border-radius: 50% !important;
    color: #ffffff !important;
    color: var(--secondar-font-color) !important;
    background: #2a2c32 !important;
    background: var(--primary-font-color) !important;
}

.react-datepicker__day {
    font-weight: 600;
    font-size: 0.9em;
    font-family: "Nunito Sans", sans-serif;
    font-family: var(--primary-font-family);
}


.itemHolder {
    height: 36px;
    display: flex;
    color: #7f8084;
    padding: 8px 0;
    align-items: center;
    justify-content: center;
    border-left: 3px solid transparent;
}

.active {
    color: var(--secondar-font-color);
    border-left: 3px solid var(--secondar-font-color);
}

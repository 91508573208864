.appointmentContainer {
    width: 500px;
    border-radius: 17px;
    background: var(--fourth-container-background-color);
}

.appointmentContainerLoader {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.appointmentHeader {
    display: flex;
    padding-top: 1.8em;
    position: relative;
    align-items: center;
    padding-bottom: 1.3em;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
}

.appointmentHeaderConfirmed {
    background: var(--status-confirmed-color);
}

.appointmentHeaderDone {
    background: var(--secondary-border-color);
}

.appointmentHeaderNoShow,
.appointmentHeaderCanceled,
.appointmentHeaderDeclined,
.appointmentHeaderClientCanceled {
    background: var(--status-cancel-color);
}

.appointmentHeaderNeedApproval {
    background: var(--status-need-approval-color);
}

.appointmentHeaderStatus {
    z-index: 2;
    font-weight: 800;
    font-size: 1em;
    text-transform: uppercase;
    color: var(--secondar-font-color);
}

.appointmentHeaderClientName {
    margin-top: -3px;
    font-weight: 800;
    font-size: 1.5em;
    color: var(--secondar-font-color);
}

.appointmentHeaderBookingStatusId {
    bottom: 5px;
    right: 15px;
    font-weight: 600;
    font-size: 0.75em;
    position: absolute;
    text-transform: capitalize;
    color: var(--secondar-font-color);
}

.appointmentClientAvatarHolder {
    z-index: 1;
    top: -50px;
    width: 80px;
    height: 80px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    background: inherit;
    align-items: center;
    justify-content: center;
}

.appointmentActionsContainer {
    display: flex;
    padding-top: .5em;
    align-items: center;
    justify-content: center;
}

.appointmentActionItemContainer {
    display: flex;
    padding-left: 1.5em;
    padding-right: 1.5em;
    justify-content: center;
}

.appointmentActionItem {
    width: 35px;
    height: 35px;
    display: flex;
    cursor: pointer;
    font-size: 1.3em;
    position: relative;
    border-radius: 50%;
    overflow: visible;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    color: var(--secondar-font-color);
    border: 1px solid var(--secondary-border-color);
}

.appointmentActionItemTitle {
    bottom: -1.8em;
    font-weight: 800;
    position: absolute;
    font-size: 0.7em !important;
}

.appointmentChargeItem {
    color: var(--utility-green);
    border: 1px solid var(--utility-green);
}

.appointmentCancelItem {
    color: var(--primary-font-color);
}

.appointmentNoShowItem {
    color: var(--status-cancel-color);
    border: 1px solid var(--status-cancel-color);
}

.appointmentChargeItem:hover {
    color: var(--secondar-font-color);
    background: var(--utility-green);
}

.appointmentNoShowItem:hover {
    color: var(--secondar-font-color);
    background: var(--status-cancel-color);
}

.appointmentCancelItem:hover {
    color: var(--secondar-font-color);
    background: var(--primary-font-color);
}

.appointmentCancelItem:hover .appointmentActionItemTitle {
    color: var(--primary-font-color);
}

.appointmentChargeItem:hover .appointmentActionItemTitle {
    color: var(--utility-green);
}

.appointmentNoShowItem:hover .appointmentActionItemTitle {
    color: var(--status-cancel-color)
}

.disabledActions {
    color: var(--secondar-font-color);
    background: var(--secondary-border-color);
    border-color: var(--secondary-border-color);
}

.disabledActions:hover {
    background: var(--secondary-border-color);
    border-color: var(--secondary-border-color);
}

.disabledActions .appointmentActionItemTitle {
    color: var(--secondary-border-color);
}

.disabledActions:hover .appointmentActionItemTitle {
    color: var(--secondary-border-color);
}

.clientInfoContainer {
    padding-top: 2em;
}

.clientUpsertInfoContainer {
    padding-top: .5em;
}

.clientInfoWrapper {
    padding-top: .5em;
    padding-left: .8em;
    padding-right: .8em;
}

.divider {
    font-size: 1.1em;
    font-weight: 800;
    padding-top: .15em;
    padding-left: .7em;
    padding-bottom: .15em;
    color: var(--secondary-border-color);
    background: var(--primary-border-color);
}

.clientInfoItem {
    margin-top: 1em;
    display: flex;
    font-size: 1em;
    font-weight: 700;
    align-items: center;
    color: var(--secondary-border-color);
}

.clientInfoItem:first-child {
    margin-top: 0;
}

.clientInfoItemName {
    padding-left: .5em;
    color: var(--secondary-border-color)
}

.clientInfoItemName::first-letter {
    text-transform: uppercase;
}

.phoneRotate {
    transform: rotate(90deg);
}

.selectClientButton {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    border-radius: .3em;
    padding: .5em .5em;
    border: 1px dashed var(--ternary-border-color);
}

.selectServiceButton {
    display: flex;
    width: 100%;
    cursor: pointer;
    position: relative;
    border-radius: .3em;
    padding: .5em .5em;
    flex-direction: column;
}

.selectClientButtonText {
    font-size: 1em;
    font-weight: 600;
    padding-left: .5em;
    color: var(--secondary-border-color);
}

.clientUpsertInfoHolder {
    flex: 1;
    padding-left: .5em;
}

.clientUpsertName {
    font-size: 1em;
    font-weight: 800;
    text-transform: capitalize;
    color: var(--primary-font-color);
}

.clientUpsertPhoneNumber {
    font-size: .8em;
    padding-top: .15em;
    color: var(--secondary-border-color);
}

.walkInCheck {
    padding-top: 1em;
}

.selectClientArrowRight {
    right: .8em;
    position: absolute;
}

.selectServiceArrowRight {
    top: 25%;
    right: 1em;
    position: absolute;
}

.verified {
    right: 3em;
    position: absolute;
}

.servicesContainer {
    padding-top: 1em;
}

.servicesContainerWrapper {
    display: flex;
    padding-top: .5em;
    padding-left: 1em;
    padding-right: .8em;
}

.serviceContainerFirstItemWrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-right: 1.2em;
    justify-content: space-between;
}

.serviceContainerButtonFirstItemWrapper {
    flex: 1;
    display: flex;
    cursor: pointer;
    position: relative;
    margin-left: .15em;
    border-radius: 5px;
    flex-direction: row;
    padding-right: 1.2em;
    justify-content: space-between;
    border: 1px dashed var(--ternary-border-color);
}

.serviceContainerFirstItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.serviceInfoWrapper {
    padding-left: 10px;
}

.servicesTitle {
    font-size: 1em;
    font-weight: 700;
    color: var(--primary-font-color);
}

.serviceTime {
    font-size: .9em;
    font-weight: 700;
    margin-top: -1px;
    color: var(--secondary-border-color);
}

.employeeName {
    font-size: .95em;
    font-weight: 700;
    margin-top: -1px;
    color: var(--secondary-border-color);
}

.firstServicePrice {
    font-size: 1em;
    font-weight: 700;
    align-self: flex-end;
    padding-bottom: .3em;
    color: var(--primary-font-color);
}


.serviceContainerTotalHolder {
    display: flex;
    margin-top: 1em;
    padding-top: .5em;
    padding-right: 2em;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid var(--primary-border-color);
}

.serviceContainerTotalPlaceholder {
    font-size: 1em;
    font-weight: 700;
    color: var(--secondary-border-color);
}

.serviceContainerTotalPrice {
    font-weight: 800;
    font-size: 1em;
    color: var(--secondary-tint-color);
}

.appointmentNotesContainer {
    padding-top: 1em;
}

.appointmentNotesWrapper {
    padding-top: .5em;
    padding-left: .8em;
    padding-right: .8em;
}

.appointmentUpsertFooter {
    display: flex;
    margin-top: 1em;
    padding: 1em .8em;
    justify-content: flex-end;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    border-top: 1px solid var(--primary-border-color);
}

.upsertButtonHolder {
    display: flex;
    flex-direction: row;
}

.cancelBtnMargin {
    margin-right: 1em;
    font-weight: 600;
    color: var(--secondary-border-color);
}

.submitCancelBtn {
    min-width: 10em;
}

.cancelCheckBox {
    padding-top: 1em;
    justify-content: space-between;
}

.cancelCheckBox:last-child {
    padding-bottom: 2em;
}

.footer {
}
.selectedTimeRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.startTimeSelect {
    flex: 1;
    margin-right: .5em;
}

.endTimeSelect {
    flex: 1;
    margin-left: .5em;
}

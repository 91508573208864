
.selectServiceContainer {
    display: flex;
    cursor: pointer;
    padding-top: .7em;
    flex-direction: row;
    align-items: center;
    padding-bottom: .7em;
}

.serviceInfoHolder {
    flex: 1;
    padding-left: .5em;
}

.serviceName {
    font-weight: 600;
    overflow: hidden;
    max-width: 330px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.serviceDuration {
    font-size: 0.9em;
    padding-top: .2em;
    color: var(--secondary-border-color);
}

.leftSide {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.rightSide {
    padding-right: 1em;
}

.arrowRight {
    padding-left: .5em;
}

.footer{
    padding-top: 2em;
}

.selectClientContainer {
    display: flex;
    cursor: pointer;
    padding-top: .7em;
    flex-direction: row;
    align-items: center;
    padding-bottom: .7em;
}

.clientInfoHolder {
    flex: 1;
    padding-left: .5em;
}

.clientName {
    font-weight: 800;
    text-transform: capitalize;
}

.clientPhoneNumber {
    font-size: 0.9em;
    padding-top: .2em;
    color: var(--secondary-border-color);
}

.leftSide {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.rightSide {
    padding-right: 1em;
}

.arrowRight {
    padding-left: .5em;
}

.footer {
    padding-top: 2em;
}
.holder {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    padding: 10px 10px 10px 50px;
    /*border-bottom: 1px solid var(--primary-border-color);*/
    background: var(--fourth-container-background-color);
}


.toolbarButton {
    cursor: pointer;
    min-width: 48px;
    min-height: 48px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 8px;
    border: 1px solid var(--ternary-border-color) !important;
    background: var(--fourth-container-background-color) !important;
}

.selectedDateText {
    font-size: 15px;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 20px;
    text-transform: capitalize;
    color: var(--primary-font-color);
}

.calendarHolder {
    top: 70px;
    display: flex;
    z-index: 9999;
    position: absolute;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    background-color: var(--fourth-container-background-color);
}

.calendarHolder:before {
    content: "";
    left: 0;
    width: 0;
    right: 0;
    height: 0;
    top: -12px;
    margin: auto;
    position: absolute;
    border-style: solid;
    border-width: 0 10px 12px 10px;
    border-color: transparent transparent var(--fourth-container-background-color) transparent;
}
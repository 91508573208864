.container {
}

.containerWrapper {
    display: flex;
    padding-left: 10px;
    border-radius: 5px;
    padding-right: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--ternary-border-color);
    border-left: 4px solid var(--ternary-border-color);
}

.title {
    font-size: 1em;
    font-weight: 800;
    color: var(--secondary-tint-color);
}

.day {
    margin-top: -2px;
    font-weight: 800;
}

.time {
    margin-top: -2px;
    font-weight: 800;
    color: var(--secondary-border-color);
}
.eventContainer {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    margin-top: 1px;
    padding-top: 3px;
    padding-left: 5px;
    border-radius: 6px;
    border-left: 5px solid;
}

.eventContainer.shadow {
    padding-top: 5px !important;
    padding-left: 10px !important;
    border-left: 5px solid var(--primary-font-color) !important;
    /*box-shadow: rgba(14, 30, 37, 0.12) 0 2px 4px 0, rgba(14, 30, 37, 0.32) 0 2px 16px 0;*/
}

.infoWrapper {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex-direction: column;
    text-overflow: ellipsis;
}

.timeText {
    display: flex;
    font-size: 14px;
    font-weight: 800;
    padding-right: 5px;
    color: var(--primary-font-color);
}

.titleText {
    font-size: 13px;
    font-weight: 600;
    padding-top: 0.06em;
    text-overflow: ellipsis;
    color: var(--primary-font-color);
}

.bullet {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 6px !important;
}

.allDayTimeText {
    display: flex;
    font-size: 14px;
    font-weight: 800;
    padding-right: 5px;
    color: var(--danger-primary-color);
}

.indicatorHolder {
    bottom: 0;
    right: 5px;
    position: absolute;
}

.noteIndicatorHolder {
    top: 5px;
    right: 5px;
    position: absolute;
}
.container {
    padding: 10px 60px 40px;
    position: relative;
}

.separator {
    width: 100%;
    height: 67px;
    border-bottom: 1px solid #dddddd;
}

.hidden {
    display: none;
}

.calendar {
    border: none;
}

.navButtons {
    display: flex;
    cursor: pointer;
    min-width: 40px;
    min-height: 40px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    /*border: 1px solid var(--ternary-border-color) !important;*/
    background: var(--fourth-container-background-color) !important;
}

.verticalSeparator{
    right: -1px;
    top: 67px;
    bottom: 0;
    width: 1px;
    z-index: 1;
    position: absolute;
    background-color: #dddddd;
}

.baseModalHolder {
    width: 500px;
    border-radius: 17px;
    background: var(--fourth-container-background-color);
}

.contentHolder {

}

.baseModalHeader {
    display: flex;
    padding-top: 1.8em;
    position: relative;
    align-items: center;
    padding-bottom: 1.3em;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
}

.baseModalFooter {
    flex: 1;
    width: 100%;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    flex-direction: column;
}

.baseModalFooterWrapper {
    padding-left: .8em;
    padding-top: 1em;
    padding-right: .8em;
    border-top: 1px solid var(--primary-border-color);
}


.baseModalAvatarHolder {
    z-index: 1;
    top: -50px;
    width: 80px;
    height: 80px;
    display: flex;
    border-radius: 50%;
    position: absolute;
    background: inherit;
    align-items: center;
    justify-content: center;
}

.baseModalHeaderSubTitle {
    z-index: 2;
    font-size: 1em;
    font-weight: 800;
    text-transform: uppercase;
    color: var(--secondar-font-color);
}

.baseModalHeaderTitle {
    margin-top: -3px;
    font-weight: 800;
    font-size: 1.5em;
    color: var(--secondar-font-color);
}

.textBottom {
    bottom: 5px;
    right: 15px;
    font-weight: 600;
    font-size: 0.75em;
    position: absolute;
    text-transform: capitalize;
    color: var(--secondar-font-color);
}

.popupContainer {
    flex: 1;
    display: flex;
    padding-left: .8em;
    padding-right: .8em;
    flex-direction: column;
}

.popupContentHolder {
    padding-top: 1em;
}

.popupContentWrapper {
    padding-top: .8em;
    padding-left: .8em;
    padding-right: .8em;
}

.popupContentDivider {
    font-size: 1.1em;
    font-weight: 800;
    padding-top: .15em;
    padding-bottom: .15em;
    color: var(--secondary-border-color);
    background: var(--primary-border-color);
}

.hideDivider {
    display: none;
}


.popupFooterDefaultRow {
    display: flex;
    justify-content: flex-end;
}

.popupFooterDefaultRowWrapper {
    display: flex;
}


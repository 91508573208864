.container {
    width: 100%;
    display: flex;
    padding-left: 50px;
    padding-right: 50px;
    flex-direction: column;
}

.workScheduleContainer {
    display: flex;
    border-radius: 0 0 10px 10px;
    border: 1px solid var(--primary-border-color);
}

.weekDisplay {
    display: flex;
    margin-top: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: var(--secondar-font-color);
    background-color: var(--primary-font-color);
}

.dayDisplay {
    flex: 1;
    font-size: 1em;
    font-weight: 700;
    padding-top: 15px;
    padding-bottom: 15px;
    text-transform: capitalize;
    border-right: 1px solid var(--primary-border-color);
}

.dayDisplay:last-child {
    border-right: none;
}

.dayDisplay:first-child {
    border-left: 1px solid var(--primary-border-color);
}

.resourceHolder {
    width: 230px;
    display: flex;
    max-width: 230px;
    flex-direction: column;
}

.resourceItem {
    flex: 1;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    text-overflow: ellipsis;
    border-right: 1px solid var(--primary-border-color);
    border-bottom: 1px solid var(--primary-border-color);
}

.resourceItemWrapper {
    display: flex;
    padding-left: 15px;
    align-items: center;
}

.resourceItem:last-child {
    border-bottom: none;
}

.resourceTitle {
    font-size: 1em;
    font-weight: 600;
    padding-left: 10px;
    color: var(--secondary-border-color);
}

.weekHolder {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.weekRow {
    display: flex;
}

.weekItem {
    flex: 1;
    display: flex;
    cursor: pointer;
    margin-bottom: -0.30px;
    color: var(--secondary-border-color);
    border-bottom: 1px solid var(--primary-border-color);
    border-right: 1px solid var(--primary-border-color);
}

.itemWrapper {
    flex: 1;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.weekRow:last-child .resourceItem {
    border-bottom: none;
}

.weekRow:last-child .weekItem {
    border-bottom: none;
}

.weekItem:last-child {
    border-right: none;
}

.shiftItem {
    flex: 1;
    width: 100%;
    display: flex;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 5px;
    border-radius: 5px;
    padding-top: 10px;
    align-items: center;
    padding-bottom: 10px;
    justify-content: center;
    color: var(--secondar-font-color);
}

.shiftItem:hover {
    background-image: none;
    background-color: var(--primary-tint-color) !important;
}

.shiftItem:hover .standardTimeText {
    color: var(--primary-font-color);
}

.shiftItem:hover .shiftText {
    opacity: 1;
    color: var(--primary-font-color);
}


.shiftItem:hover .nonWorking {
    color: var(--primary-font-color);
}

.shiftItem:last-child {
    margin-bottom: 0;
}

.nonWorking {
    background-size: 20px 20px;
    color: var(--secondary-border-color);
    background-color: rgba(215, 215, 215, 0.4);
    background-image: url("https://res.cloudinary.com/barbershop-branding/image/upload/v1632544843/Tekica%20Web/calendar-pattern_mrqu5y.svg");
}

.standardTime {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-blue-100);
}

.standardTimeText {
    font-size: 0.9em;
    color: var(--secondary-border-color);
}

.shiftText {
    opacity: 0.8;
    color: inherit;
    font-size: 0.9em;
}

.defaultItem {
    flex-direction: column;
    color: var(--secondar-font-color);
    background: var(--utility-blue-100) default;
}

.firstItem {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-brown);
}

.secondItem {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-green);
}

.thirdItem {
    flex-direction: column;
    color: var(--secondar-font-color);
    background-color: var(--utility-brown);
}

.upsertContainer {
    flex: 1;
    width: 100%;
    padding-top: 1em;
}

.upsertContainerRow {
    display: flex;
    padding-left: 2px;
    margin-bottom: 1em;
}

.upsertContainerRow:last-child {
    margin-bottom: 0;
}

.upsertContainerItem {
    flex: 1;
    text-align: left;
    margin-right: 1em;
}

.upsertContainerItem div {
    cursor: pointer;
}

.upsertContainer:last-child {
    margin-right: 0;
}

.upsertContainerFooter {
    display: flex;
    flex-direction: row;
}

.btnSave {
    margin-left: .5em;
}

.btnCancel {
    margin-right: .5em;
}

.addNewSchedule {
    flex: 1;
    border: none;
    outline: none;
    display: flex;
    font-size: 1em;
    cursor: pointer;
    background: none;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    color: var(--secondary-tint-color);
}

.addNewSchedule i {
    margin-left: .5em;
}

.repeatRuleContainer {
    display: flex;
    text-align: left;
    padding-top: 1.5em;
}

.repeatRuleContainer:last-child {
    padding-top: 1.5em;
    padding-bottom: 0;
}

.repeatRuleItem {
    flex: 1;
}

.repeatRuleItem:first-child {
    margin-right: 1em;
}

.repeatRuleItem:last-child {
    margin-right: 1em;
}

.label {
    font-size: 0.9em;
    font-weight: 700;
    padding-left: 0.3em;
    padding-bottom: .3em;
    color: var(--primary-font-color);
    font-family: var(--primary-font-family);
}

.repeatDayItem {
    /*flex: 1;*/
    cursor: pointer;
    margin-right: 0.7em;
}

.dayItem {
    width: 40px;
    height: 40px;
    display: flex;
    font-weight: 700;
    font-size: 0.75em;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    color: var(--primary-font-color);
    background: var(--primary-border-color);
}

.dayItem:hover {
    color: var(--primary-font-color);
    background: var(--primary-tint-color);
}

.selectedDay {
    font-weight: 800;
    color: var(--primary-font-color);
    background: var(--primary-tint-color);
}

.workingIndicator{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.workingIndicatorLabel{
    font-weight: 700;
    color: var(--secondary-border-color);
}

@media only screen and (max-width: 1024px) {
    .resourceTitle {
        display: none;
    }

    .resourceItemWrapper {
        padding-left: 0;
    }
}

.resourceHolder {
    display: flex;
    cursor: pointer;
    padding-top: 10px;
    align-items: center;
    padding-bottom: 10px;
    align-self: flex-start;
}

.infoHolder {
    display: flex;
    padding-left: 10px;
    flex-direction: column;
    align-items: flex-start;
}

.resourceTitle {
    font-size: 1em;
    font-weight: 700;
    color: var(--primary-font-color);
}

.workingHours {
    font-size: 14px;
    font-weight: 800;
    color: var(--secondary-border-color);
}

.resourceWorkingHoursHolder {
    display: flex;
    flex-direction: row;
}

.noTimeLabel {
    font-size: 14px;
    font-weight: 800;
    line-height: 1.5714em;
    color: var(--secondary-border-color);
}

.label{
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
    line-height: 1.5714em;
    color: var(--secondary-border-color);
}
/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,400;1,600;1,700&display=swap');

:root {
    --sidebar-width: 70px;
    --navbar-height: 0px;
    --utility-brown: #A04F41;
    --utility-blue: #6485A4;
    --utility-green: #488765;
    --utility-purple: #654887;
    --utility-blue-100: #486A87;
    --utility-blue-200: #9EADBD;
    --primary-tint-color: #ddc686;
    --primary-font-color: #2a2c32;
    --secondar-font-color: #ffffff;
    --secondary-tint-color: #c87533;
    --primary-border-color: #ebebeb;
    --fourth-border-color: #8c8b88;
    --ternary-border-color: #c7cbd4;
    --danger-primary-color: #ec0c48;
    --secondary-border-color: #979797;
    --status-cancel-color: #ec0c48;
    --status-confirmed-color: #00BE70;
    --status-need-approval-color: #f2b624;
    --container-background-color: #ebebeb;
    --full-calendar-time-grid-slot-height: 33px;
    --fourth-container-background-color: #ffffff;
    --ternary-container-background-color: #19191d;
    --secondary-container-background-color: #2a2c32;
    --primary-font-family: "Nunito Sans", sans-serif;
    --appointment-fully-paid-color: rgba(151, 151, 151, .15);
    --appointment-fully-paid-border-color: rgb(151, 151, 151);
}

body {
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: var(--primary-font-family);
    background: url("https://res.cloudinary.com/barbershop-branding/image/upload/v1632213481/Contigo/contigo_lvjomc.jpg");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px var(--fourth-container-background-color) inset !important;
}

/**
    Scrollbars
 */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--ternary-border-color);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-border-color);
}

/** ------------------------
    Modal styles
 ---------------------------*/

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 1000;
    padding: 24px 0;
    background-color: rgba(0, 0, 0, 0.65) !important;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.fc.fc-media-screen {
    width: 100%;
}

.fc-scrollgrid {
    /*border: none !important;*/
}

.fc-timegrid-slot-label.fc-timegrid-slot-minor {
    border-top: 0 !important;
}

.fc .fc-timegrid-slot-minor {
    border-top-color: rgba(221, 221, 221, .45);
}

.fc-scrollgrid-shrink {
    border: 0 !important;
}

.fc-timegrid-slot-label-cushion {
    font-size: 1em;
    font-weight: 700;
    transform: translateY(-12px);
    color: var(--secondary-border-color);
}

.fc-timegrid-axis-cushion {
    color: var(--secondary-border-color) !important;
}

.fc .fc-timegrid-col.fc-day-today {
    background-color: transparent !important;
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
}

.fc-timegrid-event .fc-event-main {
    padding: 0 !important;
    background: var(--fourth-container-background-color);
}

.fc-v-event {
    border: none !important;
    background-color: transparent !important;
}

.fc-h-event {
    border: none !important;
    background-color: transparent !important;
}

.fc-non-business {
    background-color: transparent;
    background-size: 25px 25px !important;
    background-image: url("https://res.cloudinary.com/barbershop-branding/image/upload/v1632544843/Tekica%20Web/calendar-pattern_mrqu5y.svg") !important;
}

.fc .fc-highlight {
    background: transparent !important;
}

.fc-scrollgrid {
    border: none !important;
}

.resourceHolder {
    border-right: none !important;
    border-left: none !important;
}

.fc-scrollgrid-section-header {
    /*box-shadow: rgba(50, 50, 105, 0.20) 0 5px 5px 0, rgba(0, 0, 0, 0.05) 0 1px 1px 0;*/
}

.fc-timegrid-axis {
    border-right: none !important;
    /*border-bottom: none !important;*/
}

/*.fc-timegrid-axis-frame {*/
/*    min-height: 67px !important;*/
/*}*/

.fc-scrollgrid-liquid {
    border-top: none !important;
}

.fc-timegrid-axis-frame {
    border-right: none !important;
}

.fc-scrollgrid-section-header > td:first-child {
    border: none !important;
}

.fc-scrollgrid-section-header > td:last-child {
    /*border: none !important;*/
}

.fc-scrollgrid-section-header .fc-scroller {
    overflow-y: hidden !important;
}

.fc-cell-shaded {
    /*display: none;*/
    background: var(--fourth-container-background-color) !important;
    box-shadow: rgba(50, 50, 105, 0.20) 0 5px 5px 0, rgba(0, 0, 0, 0.05) 0 1px 1px 0;
}

.fc-scrollgrid-section-body > td {
    border-bottom: none;
}

.fc-scrollgrid-sync-table .fc-resource {
    border-right: none !important;
    border-left: none !important;
}

.fc-timegrid-slot {
    height: var(--full-calendar-time-grid-slot-height) !important;
}

.resourceHolder {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.react-calendar__navigation {
    height: 68px !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid #dddddd;
}

.react-calendar__month-view__weekdays {
    align-items: center;
    height: 51px !important;
}

.react-calendar__tile--now {
    border-radius: 8px;
    color: var(--secondar-font-color) !important;
    background-color: var(--secondary-border-color);
}

.react-calendar__tile {
}

.react-calendar__tile--active {
    border-radius: 8px;
    color: var(--secondar-font-color) !important;
    background-color: var(--secondary-container-background-color) !important;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    border-radius: 8px;
    font-size: 1.1em !important;
    font-weight: 800 !important;
    text-transform: capitalize !important;
    color: var(--primary-font-color) !important;
    font-family: var(--primary-font-family) !important;
}

.react-calendar__tile:hover {
    border-radius: 8px;
    color: var(--secondar-font-color) !important;
    background-color: var(--secondary-container-background-color) !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.7;
    color: var(--secondary-border-color) !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent !important;
}

.non-working {
    font-size: 0.8em;
    font-weight: 700;
    color: var(--danger-primary-color);
    font-family: var(--primary-font-family);
}

.working-day {
    font-size: 0.8em;
    font-weight: 600;
    color: var(--primary-font-color);
    font-family: var(--primary-font-family);
}

.react-calendar__navigation__label {
    background: var(--fourth-container-background-color) !important;
}

.react-calendar__month-view__weekdays__weekday {
    font-weight: 700;
    font-size: 1.1em;
    text-transform: capitalize;
    color: var(--secondary-border-color);
    font-family: var(--primary-font-family);
}

/**
    React date picker
 */

.react-datepicker__input-container {
    height: 36px;
    display: flex;
    margin-top: 2px;
    max-height: 38px;
    padding-left: 6px;
    border-radius: 0.4rem;
    border: 1px solid hsl(0, 0%, 70%);
}

.react-datepicker__input-container:after {
    top: 0;
    bottom: 0;
    right: 5px;
    width: 20px;
    height: 20px;
    margin-top: 10px;
    position: absolute;
    font-weight: 900;
    content: "\f133";
    cursor: pointer;
    color: hsl(0, 0%, 80%);
    font-family: "FontAwesome", serif;
}

.react-datepicker__input-container:hover {
    border: 1px solid var(--primary-font-color);;
}

.react-datepicker__input-container:focus-within {
    border: 1px solid var(--primary-font-color);
}

.react-datepicker__input-container input {
    width: 95%;
    height: 90%;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 0.9em;
    text-transform: capitalize;
}

.react-datepicker__input-container input:disabled {
    background: transparent;
}

.react-datepicker__current-month {
    text-transform: capitalize;
    font-family: var(--primary-font-family);
    color: var(--secondar-font-color) !important;
}

.react-datepicker__header {
    background: var(--primary-font-color) !important;
}

.react-datepicker__day-names .react-datepicker__day-name {
    font-size: 0.9em;
    font-weight: 700;
    text-transform: capitalize;
    color: var(--secondary-border-color);
    font-family: var(--primary-font-family);
}

.react-datepicker__day--selected {
    border-radius: 50% !important;
    color: var(--secondar-font-color) !important;
    background: var(--primary-font-color) !important;
}

.react-datepicker__day {
    font-weight: 600;
    font-size: 0.9em;
    font-family: var(--primary-font-family);
}

.primaryButton {
    width: 100%;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--secondar-font-color);
    border: 1px solid var(--secondary-border-color);
    background: var(--secondary-container-background-color);
}

.outlineButton {
    width: 100%;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--primary-font-color);
    border: 1px solid var(--ternary-border-color);
    background: var(--fourth-container-background-color);
}

.dangerButton {
    width: 100%;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--secondar-font-color);
    background: var(--danger-primary-color);
    border: 1px solid var(--danger-primary-color);
}

.flatButton {
    width: 100%;
    border: none;
    line-height: 1;
    font-size: 13px;
    cursor: pointer;
    font-weight: 800;
    padding: 15px 27px;
    border-radius: 8px;
    text-transform: uppercase;
    color: var(--primary-font-color);
    background: var(--fourth-container-background-color);
}

